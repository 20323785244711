import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useFetchers,
  useNavigation,
} from "@remix-run/react";
import polarisStyles from "@shopify/polaris/build/esm/styles.css?url";
import NProgress from "nprogress";
import nProgressStyles from "nprogress/nprogress.css?url";
import { useEffect, useMemo } from "react";
import { ExternalScripts } from "remix-utils/external-scripts";
import { Toaster, toast } from "sonner";
import rootStyles from "./root.css?url";

NProgress.configure({ showSpinner: false });

export const links = () => [
  { rel: "stylesheet", href: nProgressStyles },
  { rel: "stylesheet", href: rootStyles },
  { rel: "stylesheet", href: polarisStyles },
];

export default function App() {
  const { state } = useNavigation();
  const fetchers = useFetchers();
  const states = useMemo(() => {
    return [state, ...fetchers.map((fetcher) => fetcher.state)];
  }, [state, fetchers]);
  useEffect(() => {
    if (!states.every((state) => state === "idle")) NProgress.start();

    if (states.every((state) => state === "idle")) NProgress.done();
  }, [states]);

  // Toast notification
  useEffect(() => {
    // Create a toast and after 5 seconds destroy the fetcher
    const idleFetchers = fetchers.filter((fetcher) => fetcher.state === "idle");
    const lastFetcher = idleFetchers[idleFetchers.length - 1];
    if (lastFetcher?.data?.error) {
      toast(lastFetcher.data.error.message, {
        id: lastFetcher.key,
      });
    }
  }, [fetchers]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <link rel="preconnect" href="https://cdn.shopify.com/" />
        <link
          rel="stylesheet"
          href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css"
        />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet />
        <Toaster theme="dark" />
        <ScrollRestoration />
        <Scripts />
        <ExternalScripts />
      </body>
    </html>
  );
}
